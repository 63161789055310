import { reactive, toRefs, onMounted, watch, computed } from 'vue'
import store from '@/store'
import { i18n } from '@/i18n'
import route from '@/router'
import debounce from 'lodash/debounce'
import { getWarehouseList } from '@/api/warehouse'

export default () => {
  const routeName = route.app._route.name
  const state_ = reactive({
    warehouse_list: [],
    columns : [
      {
        title: i18n.t('warehouse.code_warehouse'),
        dataIndex: 'code',
      },
      {
        title: i18n.t('warehouse.name_warehouse'),
        dataIndex: 'name',
      },
      {
        title: i18n.t('warehouse.ext_code'),
        dataIndex: 'ext_code',
      },
      {
        title: i18n.t('distributor.country'),
        dataIndex: 'address.country_name',
      },
      {
        title: i18n.t('distributor.city'),
        dataIndex: 'address.city_name',
      },
      {
        title: i18n.t('distributor.district'),
        dataIndex: 'address.district_name',
      },
      {
        title: i18n.t('distributor.address'),
        dataIndex: 'address.street_name',
      },
      {
        title: 'Status',
        dataIndex: 'status',
      },
      {
        title: i18n.t('distributor.action'),
        dataIndex: 'id',
        align: 'center',
        scopedSlots: { customRender: 'action' },
        fixed: 'right',
        width: 150,
      },
    ],
    pagination: {
      size: 20,
      total_items: 0,
    },
    searchParam: route.app._route.query.query || '',
  })

  const businessId = computed(() => {
    return store.state.user.restriction_base === 'Warehouse'
        ? Object.keys(store.getters['user/businessListObjectByKey'])[0]
        : route.app._route.query.business_id
  })

  onMounted(
    () => {
    fetchWarehouse()
  })

  watch(
    () => route.app._route.query, () => {
      if (route.app._route.name == routeName) {
        fetchWarehouse()
      }
    },
  )

  watch(
    () => businessId.value, () => {
      fetchWarehouse()
    },
  )

  const page = computed(() => {
    return +route.app._route.query.page || 1
  })

  const fetchWarehouse = debounce(async () => {
    await getWarehouseList({
      size: state_.pagination.size,
      page: page.value,
      business_id: businessId.value,
      query: route.app._route.query.query,
    })
    .then(({ data }) => {
      state_.warehouse_list = !!route.app._route.query.warehouse_id
        ? data.data.filter(warehouse => warehouse.id === route.app._route.query.warehouse_id)
        : data.data
      state_.pagination.total_items = !!route.app._route.query.warehouse_id
        ? state_.warehouse_list.length
        : data.total_items
    })
    .catch((err) => console.error(err))
  }, 500)

  const changePage = (value) => {
    route.push({
      query: { ...route.app._route.query, page: value, query: undefined },
    })
  }

  const handleSearch =  debounce((e) => {
    const q = e.target.value
    state_.searchParam = q
    route.push({
      query: {
        ...route.app._route.query,
        query:state_.searchParam || undefined,
        page: undefined,
      },
    })

  }, 500)

  return { ...toRefs(state_), changePage, page, handleSearch }
}