<template>
  <div>
    <h1 class="h3">
      {{ $t('warehouse.list_warehouse') }}
    </h1>
    <div class="d-flex justify-content-between">
      <div class="mt-4">
        <a-input-search
          v-model="searchParam"
          placeholder="Search"
          enter-button="Search"
          size="large"
          style="width: 350px"
          @change="handleSearch"
        />
      </div>
      <div class="mt-4">
        <button v-if="permission.includes('READ')" class="ant-btn ant-btn-primary ant-btn-lg mr-3">
          {{ $t('warehouse.export_data') }}
        </button>
        <router-link
          v-if="permission.includes('WRITE')"
          :disabled="$store.state.products.backgroundNotificationAdjustWarehouseProduct"
          :to="to(`/warehouse/add-warehouse`)"
          class="ant-btn ant-btn-primary ant-btn-lg"
        >
          {{ $t('warehouse.add_warehouse') }}
        </router-link>
      </div>
    </div>
    <div class="mt-5">
      <a-card style="border-radius: 10px">
        <a-table 
          :scroll="{ x: 1300 }"
          :data-source="warehouse_list"
          :row-key="(item) => item.id"
          :pagination="false"
          style="cursor: pointer"
          :columns="columns"
          :custom-row="(record) => ({
            on: {
              click: () => handleRowTableClick(record)
            }
          })"
        >
          <!-- <template #name="{ text }">
          <a>{{ text }}</a>
        </template> -->
          <template slot="action" slot-scope="text, record">
            <div class="text-nowrap">
              <a-dropdown placement="bottomRight" @click.prevent>
                <a-button
                  type="link"
                  size="small"
                  class="text-dark font-weight-bold"
                  style="font-size: 20px; line-height: 0"
                  @click="e => e.stopPropagation()"
                >
                  <a-icon type="menu" />
                </a-button>
                <a-menu slot="overlay">
                  <a-menu-item
                    class="py-3"
                    @click="(e) => (
                      viewDetail = false,
                      $router.push({
                        path: `/warehouse/update-warehouse/${text}`,
                        query: $route.query,
                      })
                    )"
                  >
                    <a-icon type="bank" />
                    Ubah Data Gudang
                  </a-menu-item>
                  <a-menu-item
                    class="py-3"
                    @click="onShowModalAdjustProduct(record)"
                  >
                    <a-icon type="gold" />
                    Atur Produk
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
          </template>
        </a-table>
      </a-card>
    </div>

    <a-col :span="24" class="d-flex justify-content-end my-5">
      <Pagination
        :total="pagination.total_items"
        :page-size="pagination.size"
        :total-length-per-page="warehouse_list.length"
        :default-page="page"
        @changePage="changePage"
      />
    </a-col>
    <a-drawer
      placement="right"
      :closable="true"
      :visible="viewDetail"
      width="700"
      @close="viewDetail = false, dataDrawerDetail = null"
    >
      <template #title>
        <div class="d-flex">
          <div class="mr-3">
            {{ $t('warehouse.detail_warehouse') }}
          </div>
          <div v-if="dataDrawerDetail?.wms_code" class="wms-tag px-2 py-1 rounded-lg">
            {{ isConnectedWarehouse }}
          </div>
        </div>
      </template>
      <div class="d-flex flex-column drawer-container">
        <b class="pbz-font body-md-small mb-2">{{ $t('warehouse.information_warehouse') }}</b>
        <div class="d-flex justify-content-between align-item-center">
          <div class="title">
            Nama Gudang
          </div>
          <div class="desc">
            {{ dataDrawerDetail?.name }}
          </div>
        </div>
        <div class="d-flex justify-content-between align-item-center">
          <div class="title">
            Kode Gudang
          </div>
          <div class="desc">
            {{ dataDrawerDetail?.code }}
          </div>
        </div>
        <div class="d-flex justify-content-between align-item-center">
          <div class="title">
            Kode Eksternal
          </div>
          <div class="desc">
            {{ dataDrawerDetail?.ext_code ?? '-' }}
          </div>
        </div>
        <div class="d-flex justify-content-between align-item-center">
          <div class="title">
            PIC Gudang
          </div>
          <div class="desc">
            {{ dataDrawerDetail?.contact_name }}
          </div>
        </div>
        <div class="d-flex justify-content-between align-item-center">
          <div class="title">
            Nomor Handphone
          </div>
          <div class="desc">
            {{ dataDrawerDetail?.contact_phone }}
          </div>
        </div>
        <a-divider dashed />
        <b class="pbz-font body-md-small mb-2">{{ $t('warehouse.address_warehouse') }}</b>
        <div class="d-flex justify-content-between align-item-center">
          <div class="title">
            Negara
          </div>
          <div class="desc">
            {{ dataDrawerDetail?.address?.country_name }}
          </div>
        </div>
        <div class="d-flex justify-content-between align-item-center">
          <div class="title">
            Kota
          </div>
          <div class="desc">
            {{ dataDrawerDetail?.address?.city_name }}
          </div>
        </div>
        <div class="d-flex justify-content-between align-item-center">
          <div class="title">
            Kecamatan
          </div>
          <div class="desc">
            {{ dataDrawerDetail?.address?.sub_district_name }}
          </div>
        </div>
        <div class="d-flex justify-content-between align-item-center">
          <div class="title">
            Kodepos
          </div>
          <div class="desc">
            {{ dataDrawerDetail?.address?.postal_code }}
          </div>
        </div>
        <div class="d-flex justify-content-between align-item-center">
          <div class="title">
            Alamat
          </div>
          <div class="desc">
            {{ dataDrawerDetail?.address?.street_name }}
          </div>
        </div>
        <a-divider dashed />
        <b class="pbz-font body-md-small mb-2">{{ $t('warehouse.connected_with') }}</b>
        <div class="d-flex justify-content-between align-item-center">
          <div class="title">
            Koneksi Gudang
          </div>
          <div class="desc">
            {{ dataDrawerDetail?.wms_code ? dataDrawerDetail?.wms_code : 'Tidak Terkoneksi' }}
          </div>
        </div>
        <template v-if="dataDrawerDetail?.wms_code">
          <div class="d-flex justify-content-between align-item-center">
            <div class="title">
              {{ dataDrawerDetail?.wms_code === 'jubelio' ? 'Username' : 'Auth Key' }}
            </div>
            <div class="desc">
              {{ dataDrawerDetail?.param1 }}
            </div>
          </div>
          <div class="d-flex justify-content-between align-item-center">
            <div class="title">
              {{ dataDrawerDetail?.wms_code === 'jubelio' ? 'Password' : 'Secret Key' }}
            </div>
            <div class="desc">
              {{ dataDrawerDetail?.param2 }}
            </div>
          </div>
          <div class="d-flex justify-content-between align-item-center">
            <div class="title">
              API URL
            </div>
            <div class="desc-url">
              {{ dataDrawerDetail?.param3 }}
            </div>
          </div>
        </template>
      </div>
    </a-drawer>
    <ModalConfirmationAdjustmentProduct
      :form="form"
      :visible="showModalConfirmationAdjustmentProduct"
      :whs-id="responseAddWarehouseId"
      @handleModal="handleShowModalConfirmationAdjustmentProduct"
    />
  </div>
</template>

<script>
import useQueryParam from '@/composable/useQueryParam'
import warehouse_ from './composables/warehouse'
import Pagination from '@/components/Pagination'
import ModalConfirmationAdjustmentProduct from '@/views/setting/warehouse/ModalConfirmationAdjustmentProduct.vue'
// import { type } from 'os'

export default {
  name: 'ListWarehouse',
  components: {
    Pagination,
    ModalConfirmationAdjustmentProduct,
  },
  setup() {
    const { to } = useQueryParam()
    const { warehouse_list, columns, pagination, page, changePage, handleSearch, searchParam } = warehouse_()

    return { to, columns, warehouse_list, pagination, page, changePage, handleSearch, searchParam }
  },
  data() {
    return {
      viewDetail: false,
      dataDrawerDetail: null,
      showModalConfirmationAdjustmentProduct: false,
      responseAddWarehouseId: null,
      form: null,
    }
  },
  computed: {
    permission() {
      return this.$store.getters['user/can'](this.$route.meta.key)
    },
    isConnectedWarehouse() {
      return this.dataDrawerDetail?.wms_code ? `${this.$t('warehouse.connected_with')} ${this.dataDrawerDetail?.wms_code}` : ''
    },
  },
  // watch: {
  //   permission(newValue) {
  //     if(!newValue.length) {
  //      this.$router.push({path: '/error/403', query: {...this.$route.query}})
  //     }
  //   },
  // },
  methods: {
    onShowModalAdjustProduct(record) {
      console.log('onShowModalAdjustProduct record: ', record)
      this.responseAddWarehouseId = record.id
      this.showModalConfirmationAdjustmentProduct = true
      this.form = record
    },
    handleShowModalConfirmationAdjustmentProduct(param) {
      this.showModalConfirmationAdjustmentProduct = param
      if (!param) {
        this.form = null
        this.responseAddWarehouseId = null
      }
    },
    handleRowTableClick(record) {
      this.viewDetail = true
      this.dataDrawerDetail = record
    },
  },
}
</script>

<style lang="scss" scoped>
.wms-tag {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #2196F3;
  background-color: #E3F2FD;
}
.drawer-container {
  gap: 3px 0px;
  .title {
    width: 30%;
    color: var(--secondary);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: left;
  }
  .desc {
    width: 70%;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: right;
    &-url {
      color: #0AADFF;
      text-decoration: underline;
    }
  }
}
</style>
